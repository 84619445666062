<template>
  <div>
    <div id="chart">
      <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "LineBarChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: ["data", "chartTitle"],
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 500,
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
          },
        },
        // stroke: {
        //   curve: "smooth",
        // },
        title: {
          text: this.chartTitle,
        },
        colors: this.data.colors,
        dataLabels: {
          enabled: true,
          // enabledOnSeries: [1],
        },
        // labels: this.data.labels,
        xaxis: {
          categories: this.data.labels,
          decimalsInFloat: 2,
          // title: {
          //   text: "Month",
          // },
        },
        yaxis: this.data.yaxis,
      };
    },
    series() {
      return this.data.dataSeries;
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
